import React from 'react'
import './sponsor.css'
import logo from '../../resources/Logo.png'
import logo1 from '../../resources/Logo-1.png'
import logo2 from '../../resources/Logo-2.png'
import logo3 from '../../resources/Logo-3.png'
import logo4 from '../../resources/Logo-4.png'
import logo5 from '../../resources/Logo-5.png'
import logo6 from '../../resources/Logo-6.png'
import logo7 from '../../resources/Logo-7.png'

const Sponsor = () => {
  return (
    <div className="sponsorContent">
      <p>For Sponsorships/Partnerships Please Contact <br />
      info.cyignitecon@gmail.com </p>
      {/*<div className='logoContainer'><img src={logo} alt="logo" /></div>
      <div className='logoContainer'><img src={logo1} alt="logo" /></div>
      <div className='logoContainer'><img src={logo2} alt="logo" /></div>
      <div className='logoContainer'><img src={logo3} alt="logo" /></div>
      <div className='logoContainer'><img src={logo4} alt="logo" /></div>
      <div className='logoContainer'><img src={logo5} alt="logo" /></div>
      <div className='logoContainer'><img src={logo6} alt="logo" /></div>
  <div className='logoContainer'><img src={logo7} alt="logo" /></div> */}
    </div>
  )
}

export default Sponsor
